<template>
	<div>
		<div class="flex-col rounded beautiful text-zinc-600" style="overscroll-behavior:auto;"
			:ref="interpretation_id">

			<!-- <div
				class="z-40 flex fixed text-[1rem] mt-[2vh]  text-yellow-400 justify-end  w-full clear  pr-[31.15vw]">
				<button class="hover:text-yellow-600 hover:underline" @click="toggleOptions()">More Options</button>
			</div> -->

			<!-- <div
				class="z-40 flex fixed text-[3rem] -mt-[48.5px] text-yellow-400 justify-end  w-full clear  pr-[31.15vw]">
				<button class="hover:text-yellow-600" @click="toggleOptions()">&hellip;</button>
			</div> -->
			
			<!-- <p class="flex pb-[32px] mt-[34px] font-semibold text-rose-900">Click:</p> -->
			<div class="pl-[1.2vw] mt-[72px]">
				<span v-for="substring in arrayForRenderingHighlights" :key="substring.startingcharacter"
					:id="substring.highlighted">

					<!-- style="scroll-margin-bottom: 23vh;" -->
					<!-- :ref="el => {functionRef(el)}" -->
					<span v-if="substring.scrollTo == 0" class="cursor-pointer" @click="snapToTimestamp(substring)" :style="substring.text === '\n\n' ? {'display': 'block', 'line-height': '12.5px'} : {}"
						style="white-space: pre-wrap">{{ substring.text }}</span>
					<span v-else-if="substring.scrollTo == 1" class="text-orange-800 cursor-pointer" style="white-space: pre-wrap" :style="substring.text === '\n\n' ? {'display': 'block', 'line-height': '12.5px'} : {}"
						@click="snapToTimestamp(substring)">{{ substring.text }}</span>
					<span v-else-if="substring.scrollTo == 2" class="cursor-pointer text-cyan-400" style="white-space: pre-wrap" :style="substring.text === '\n\n' ? {'display': 'block', 'line-height': '12.5px'} : {}"
						@click="snapToTimestamp(substring)">{{ substring.text }}</span>
					<span v-else-if="substring.scrollTo >= 3" class="text-indigo-700 cursor-pointer" style="white-space: pre-wrap" :style="substring.text === '\n\n' ? {'display': 'block', 'line-height': '12.5px'} : {}"
						@click="snapToTimestamp(substring)">{{ substring.text }}</span>

				</span>
			</div>
		</div>

	</div>
</template>

<script>
// import { saveAs } from "file-saver";
import { getIdToken } from "firebase/auth";

export default {
	name: "Viewer",
	inheritAttrs: false,
	data: () => {
		return {
			language_name: "",
			title: "",
			srt: "",
			// currenthighlight: 0,
			// fontsizeoriginal: 12,
			relevantTimestamps: [],
			lastTimestamp: 0,
			nextTimestamp: 0,
			relevantCharacters: [], // character indices in the text where highlighting might need to begin or end
			parsedAssociations: [], // array of objects that each indicates which range of characters should be highlighted within a given range of milliseconds
			substringArray: [], // array of objects that each includes a substring of the displayed text, with the index of the substring's starting character
			latest_text: "", // the whole text to be displayed
			associations: null, // the raw data of associated milliseconds/characters to be highlighted, as sent from backend
			startslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			endslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			i: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			spaced_by: "",
		};
	},

	computed: {


		arrayForRenderingHighlights() {
			// we get a new one of these only every time the highlights need to change
			let tempArray = this.substringArray;
			let mappedParsedAssociations = this.parsedAssociations.map(
				(assoc) => assoc.startCharacter
			);
			// console.log("parsed associations")
			// console.log(this.parsedAssociations)
			// console.log("last timestamp was " + this.lastTimestamp)
			let firstPassHighlightsArray = [];
			for (let i = 0; i < tempArray.length; i++) {
				let k = 0;
				this.parsedAssociations.forEach((element) => {
					if (
						Number(this.lastTimestamp) >= Number(element.startTime) &&
						Number(this.lastTimestamp) < Number(element.endTime)
					) {
						// console.log(tempArray[i].startingcharacter + " should be greater than " + element.startCharacter)
						// console.log(tempArray[i].startingcharacter + " should be less than " + element.endCharacter)
						if (
							tempArray[i].startingcharacter >= element.startCharacter &&
							tempArray[i].startingcharacter < element.endCharacter
						) {
							// console.log("parsedAssociations element is " + JSON.stringify(element))
							// 	console.log("tempArray element is " + JSON.stringify(tempArray[i]))
							// console.log(element.startTime)
							// console.log(this.lastTimestamp)
							// console.log(element.endTime)
							// console.log(tempArray[i])
							k++;
						}
					}
				});

				tempArray[i].scrollTo = k; // tells us how many levels of highlight (= which color of highlight) to give that substring

				if (tempArray[i].scrollTo > 0) {
					// for each highlighted substring...
					tempArray[i].highlighted = 1;
					let temporaryindex = mappedParsedAssociations.indexOf(
						tempArray[i].startingcharacter
					);
					if (temporaryindex != -1) {
						firstPassHighlightsArray.push(
							// ...put it in another array
							this.parsedAssociations[temporaryindex]
						);
					}
				} else {
					tempArray[i].highlighted = 0;
				}
			}
			let mappedTempArray = tempArray.map((temp) => temp.startingcharacter);

			// console.log(firstPassHighlightsArray);
			// let toIncrease = []; // which ones should we give an extra nesting value?

			firstPassHighlightsArray.forEach((element) => {
				this.parsedAssociations.forEach((parsed) => {
					if (
						// make sure nested written meanings are at least highlighted a little bit even if their characters are out of order
						Number(element.startTime) <= Number(parsed.startTime) &&
						Number(parsed.endTime) <= Number(element.endTime) &&
						!(
							element.startCharacter <= parsed.startCharacter &&
							parsed.endCharacter <= element.endCharacter
						)
					) {
						let tempindex = mappedTempArray.indexOf(parsed.startCharacter);
						tempArray[tempindex].scrollTo = tempArray[tempindex].scrollTo + 1;
						tempArray[tempindex].highlighted = 1;
					}

				});
			});
			//remove gap at the top
			while (tempArray[0] && (tempArray[0].text == "\n" || tempArray[0].text == "\n\n")) {
				tempArray.shift()
			}
			// console.log("tempArray")

			return tempArray;
		},
	},

	props: {
		playingbeginning: { default: 0 },
		audio_id: {
			default: "",
		},
		downloadSRTcounter: { default: 0 },
		timestep: { default: 0 },
		fontsize: { default: 12 },
		interpretation_id: { default: "" },
		interpretationStatus: { default: "" }, // describes whether the currently logged-in user is a viewer, editor, or owner of the interpretation being viewed
	},
	watch: {
		interpretationStatus: function () {
			// console.log("1");
			this.fetchNewInterpretation();
		},
		downloadSRTcounter: function () {
			// console.log("2");
			this.downloadSRT();
		},
		timestep: function () {
			// console.log("3");
			// console.log(this.timestep)
			this.fetchNewInterpretation();
		},
		"$store.state.renewViewer": function () {
			// console.log("4");
			this.fetchNewInterpretation();
		},
		"$store.state.checkViewerHighlight": function () {
			this.rerenderHighlights();
		},
		"$store.state.audioplayertime": function () {
			this.rerenderHighlights();

		},
	},
	mounted() {
		// this.fontsizeoriginal=this.fontsize
		if (this.interpretationStatus) {
			this.fetchNewInterpretation();
		}

	},
	updated() {

		this.scrollToElement(1);
	},

	methods: {
		toggleOptions() {
			this.$store.commit("toggleOptions")
		},

		rerenderHighlights() {
			// basically always running if audio player is doing anything
			if (
				this.$store.state.audioplayertime < this.lastTimestamp ||
				this.$store.state.audioplayertime > this.nextTimestamp
			) {
				let currenttime = this.$store.state.audioplayertime;
				// REPOPULATE TIMESTAMPS
				for (let i = 0; i < this.relevantTimestamps.length; i++) {
					if (
						this.relevantTimestamps[i] <= currenttime &&
						this.relevantTimestamps[i + 1] > currenttime
					) {
						this.lastTimestamp = this.relevantTimestamps[i];
						this.nextTimestamp = this.relevantTimestamps[i + 1];
						this.$store.commit("updateRecentHighlight", this.lastTimestamp / 100)
						break;
					}
				}
			}
		},

		scrollToElement(number) {


			if (
				this.$refs[this.interpretation_id].querySelector(
					`#${CSS.escape(number)}`
				) !== null
			) {
				if (this.$store.state.consoles.length == 1) {
					// console.log("in scroll smooth");
					this.$refs[this.interpretation_id]
						.querySelector(`#${CSS.escape(number)}`)
						.scrollIntoView({ behavior: "smooth", block: "center" });
				} else {
					this.$refs[this.interpretation_id]
						.querySelector(`#${CSS.escape(number)}`)
						.scrollIntoView({ block: "center" });
					// console.log("in scroll instant");
				}
			} else {
				this.$nextTick(() => {
					if (
						this.$refs[this.interpretation_id].querySelector(
							`#${CSS.escape(number)}`
						) !== null
					) {
						if (this.$store.state.consoles.length == 1) {
							// console.log("in scroll smooth");
							this.$refs[this.interpretation_id]
								.querySelector(`#${CSS.escape(number)}`)
								.scrollIntoView({ behavior: "smooth", block: "center" });
						} else {
							this.$refs[this.interpretation_id]
								.querySelector(`#${CSS.escape(number)}`)
								.scrollIntoView({ block: "center" });
							// console.log("in scroll instant");
						}
					}
				});
			}
		},

		// access the interpretation that needs to be displayed
		async fetchNewInterpretation() {
			if (this.$store.state.user) {
				// REFRESH ID TOKEN FIRST AND WAIT FOR IT
				await getIdToken(this.$store.state.user)
					.then((idToken) => {
						this.$store.commit("SetIdToken", idToken);
						// console.log(this.$store.state.idToken)
					})
					.catch((error) => {
						// An error happened.
						console.log("Oops. " + error.code + ": " + error.message);
					});
			}

			const apiUrl =
				process.env.VUE_APP_api_URL +
				"interpretations/" +
				this.interpretation_id +
				"/audio/" +
				this.audio_id +
				"/" +
				this.interpretationStatus +
				"/";
			fetch(apiUrl, {
				method: "GET",

				headers: {
					"Content-Type": "application/json",

					Authorization: this.$store.state.idToken,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					this.title = data.interpretation.title;
					this.language_name = data.interpretation.language_name;
					this.latest_text = data.interpretation.latest_text;
					this.spaced_by = data.interpretation.spaced_by;
				})

				// access the information about what to highlight, and when, for the interpretation that is to be displayed
				.then(() => {
					// console.log(this.timestep)

					fetch(
						process.env.VUE_APP_api_URL +
						"content/" +
						this.audio_id +
						"/" +
						this.interpretation_id +
						"/" +
						this.timestep +
						"/",
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",

								Authorization: this.$store.state.idToken,
							},
						}
					)
						.then((response) => response.json())
						.then((data) => {
							this.associations = data.associations;
						})
						.then(() => this.parsed_associations()) // turn the highlighting information from backend into something usable
						.then(() => {
							this.latest_text_slices(); // split up the displayed text into substrings to be highlighted whenever necessary
						})
						.catch((error) => console.error("Error:", error));
				})

				.catch((error) => console.error("Error:", error));
		},

		parsed_associations: function () {
			this.parsedAssociations.length = 0;
			let parsedAssociations2 = []
			let parsedAssociations3 = []
			let parsedAssociations4 = []
			let parsedHelper = []
			let parsedHelper2 = []
			if (this.associations) {
				for (let i = 0; i < Object.keys(this.associations).length; i++) {
					let startTime = parseFloat(Object.keys(this.associations)[i].split("-")[0]).toFixed(2);
					let endTime = parseFloat(Object.keys(this.associations)[i].split("-")[1]).toFixed(2);
					let intervalsCount = Object.values(this.associations)[i].length;

					for (let j = 0; j < intervalsCount; j++) {
						let startCharacter = Object.values(this.associations)[i][j].split(
							"-"
						)[0];
						let endCharacter = Object.values(this.associations)[i][j].split(
							"-"
						)[1];

						let associationsObject = {};
						associationsObject.startTime = Number(startTime);
						associationsObject.endTime = Number(endTime);

						associationsObject.startCharacter = Number.parseInt(startCharacter);
						associationsObject.endCharacter = Number.parseInt(endCharacter) + 1;
						parsedAssociations2.push(associationsObject);
					}
				}
			}
			// This next part ensures that outer nested characters are associated with the full range of timestamps associated with inner nested characters.
			parsedAssociations2.sort((a, b) => b.startCharacter - a.startCharacter);
			// console.log(parsedAssociations2)
			parsedAssociations2.forEach((parsed2) => {
				parsedAssociations4.forEach((parsed1) => {
					if (parsed2.endCharacter >= parsed1.endCharacter) {
						parsed2.startTime = Math.min(parsed2.startTime, parsed1.startTime)
						parsed2.endTime = Math.max(parsed2.endTime, parsed1.endTime)
					}

				})
				parsedAssociations4.unshift(parsed2)
			}




			)




			parsedAssociations4.sort((a, b) => b.startTime - a.startTime);
			// console.log(...parsedAssociations3)

			//if the timestamps nest, then include all of the characters of both.
			parsedAssociations4.forEach((parsed4) => {
				parsedAssociations3.forEach((parsed3) => {
					if (parsed4.endTime >= parsed3.endTime && !(parsed4.startCharacter <= parsed3.startCharacter && parsed3.endCharacter <= parsed4.endCharacter)) {
						parsed4.startCharacter = Math.min(parsed4.startCharacter, parsed3.startCharacter)
						parsed4.endCharacter = Math.max(parsed4.endCharacter, parsed3.endCharacter)
					}

				})
				parsedAssociations3.unshift(parsed4)
			}
			)







			parsedAssociations3.sort((a, b) => b.startTime - a.startTime);
			// console.log(...parsedAssociations3)

			//if the timestamps are offset, then the longer one should be completely expanded to include the other's timestamp and character ranges too.
			parsedAssociations3.forEach((parsed3) => {
				this.parsedAssociations.forEach((parsed) => {
					// if (parsed3.endTime >= parsed.endTime && !(parsed3.startCharacter <= parsed.startCharacter && parsed.endCharacter <= parsed3.endCharacter)) {

					if ((parsed3.startTime <= parsed.startTime && parsed3.endTime > parsed.startTime && parsed3.endTime <= parsed.endTime) || (parsed3.startTime >= parsed.startTime && parsed3.startTime < parsed.endTime && parsed3.endTime >= parsed.endTime)) {
						if ((parsed3.endTime - parsed3.startTime) >= (parsed.endTime - parsed.startTime)) {
							parsed3.startCharacter = Math.min(parsed3.startCharacter, parsed.startCharacter)
							parsed3.endCharacter = Math.max(parsed3.endCharacter, parsed.endCharacter)
							parsed3.startTime = Math.min(parsed3.startTime, parsed.startTime)
							parsed3.endTime = Math.max(parsed3.endTime, parsed.endTime)
						}

						else {
							// expand parsed and put it back in
							parsedHelper.unshift(parsed)
							parsedHelper2.unshift(parsed3)
						}
					}


				})




				this.parsedAssociations.unshift(parsed3)
			}
			)
			// console.log(...this.parsedAssociations)
			// console.log(...parsedHelper)
			// console.log(...parsedHelper2)
			for (let i = 0; i < parsedHelper.length; i++) {
				// expand the thing at that index to include the coindexed entry of parsedHelper2
				// Done!
				// console.log(parsedHelper[i])
				let index = this.parsedAssociations.indexOf(parsedHelper[i])
				this.parsedAssociations[index].startTime = Math.min(parsedHelper[i].startTime, parsedHelper2[i].startTime)
				this.parsedAssociations[index].endTime = Math.max(parsedHelper[i].endTime, parsedHelper2[i].endTime)
				this.parsedAssociations[index].startCharacter = Math.min(parsedHelper[i].startCharacter, parsedHelper2[i].startCharacter)
				this.parsedAssociations[index].endCharacter = Math.max(parsedHelper[i].endCharacter, parsedHelper2[i].endCharacter)
				// console.log(this.parsedAssociations[index])
			}

			// console.log(...this.parsedAssociations)

		},

		downloadSRT() {
			this.srt = "";

			this.parsedAssociations.sort((a, b) => a.endCharacter - b.endCharacter);
			let captionNumber = 0;
			this.substringArray.forEach((value, index) => {
				// console.log(this.populateSRT(value.startingcharacter));
				let info = this.populateSRT(value.startingcharacter);
				// console.log(info);

				if (Object.keys(info).length > 0) {
					// console.log("HIT");
					let tempStartTimeSeconds = 0
					let tempStartTimeMilliseconds = 0
					let tempEndTimeSeconds = 0
					let tempEndTimeMilliseconds = 0

					tempStartTimeMilliseconds = Math.round(parseFloat(info.startTime * 10))



					tempStartTimeMilliseconds = tempStartTimeMilliseconds.toString()
					tempStartTimeMilliseconds = tempStartTimeMilliseconds.slice(-3)


					while (tempStartTimeMilliseconds.length < 3) {
						tempStartTimeMilliseconds += 0;
					}


					tempStartTimeSeconds = this.secondsToTime(
						info.startTime.split(".")[0].slice(0, -2)
					);





					tempEndTimeMilliseconds = Math.round(parseFloat(info.endTime * 10))


					tempEndTimeMilliseconds = tempEndTimeMilliseconds.toString()
					tempEndTimeMilliseconds = tempEndTimeMilliseconds.slice(-3)


					while (tempEndTimeMilliseconds.length < 3) {
						tempEndTimeMilliseconds += 0;
					}
					tempEndTimeSeconds = this.secondsToTime(
						info.endTime.split(".")[0].slice(0, -2)
					);



					let tempSubstring = value.text;
					// console.log(tempSubstring)
					let tempSubstringSplit = tempSubstring.split("");
					tempSubstringSplit.forEach((character, index) => {
						if (character === "\n") {
							tempSubstringSplit[index] = this.spaced_by;
						}
					});

					tempSubstring = tempSubstringSplit.join("");
					// console.log(tempSubstring)

					if (this.spaced_by != "") {
						let tempSubstringArray = tempSubstring.split("\\n");
						for (let j = 0; j < tempSubstringArray.length; j++) {
							if (tempSubstringArray[j][0] == this.spaced_by) {
								tempSubstringArray[j] = tempSubstringArray[j].substring(1);
							}
							if (
								tempSubstringArray[j][tempSubstringArray[j].length - 1] ==
								this.spaced_by
							) {
								tempSubstringArray[j] = tempSubstringArray[j].substring(
									0,
									tempSubstringArray[j].length - 1
								);
							}
						}

						// console.log(stripped.join("\n"));
						tempSubstring = tempSubstringArray.join("\\n");
						// console.log(tempSubstring)
					}

					captionNumber++;

					this.srt +=
						captionNumber +
						"\n" +
						tempStartTimeSeconds +
						"," +
						tempStartTimeMilliseconds +
						" --> " +
						tempEndTimeSeconds +
						"," +
						tempEndTimeMilliseconds +
						"\n" +
						tempSubstring +
						"\n\n";
				}
			});
			// console.log(this.srt)
			if (this.srt.slice(-2) == "\n\n") {
				this.srt = this.srt.slice(0, -2);
			}

			let blob = new Blob([this.srt], { type: "text/plain;charset=utf-8" });
			saveAs(
				blob,
				this.title +
				"-" +
				this.language_name +
				"-timestep" +
				this.timestep +
				".srt"
			);
		},

		// convert a value from seconds to HH:MM:SS
		secondsToTime(seconds) {
			var date = new Date(1970, 0, 1);
			date.setSeconds(seconds);
			return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
		},

		async latest_text_slices() {
			// console.log(this.parsedAssociations)
			this.substringArray = [];
			this.relevantCharacters.length = 0;
			await this.assignRelevantCharacters();
			// console.log(this.relevantCharacters)

			// console.log(this.relevantTimestamps);
			if (this.relevantCharacters.length > 0) {
				this.relevantCharacters.sort((a, b) => a - b);
				this.relevantCharacters = [...new Set(this.relevantCharacters)];

				if (this.relevantCharacters[0] != 0) {
					let firstslice = {};
					this.endslice = this.relevantCharacters[0];
					firstslice.text = this.latest_text.substring(0, this.endslice);
					firstslice.startingcharacter = 0;
					this.substringArray.push(firstslice);
				}

				this.i = 0;
				while (this.i + 1 < this.relevantCharacters.length) {
					let slice = {};
					this.startslice = this.relevantCharacters[this.i];
					this.endslice = this.relevantCharacters[this.i + 1];
					slice.text = this.latest_text.substring(
						this.startslice,
						this.endslice
					);
					slice.startingcharacter = this.startslice;
					this.substringArray.push(slice);
					this.i++;
				}
				let finalslice = {};
				this.startslice = this.relevantCharacters[this.i];
				finalslice.text = this.latest_text.substring(this.startslice);
				finalslice.startingcharacter = this.startslice;
				this.substringArray.push(finalslice);
			} else {
				let slice = {};
				slice.text = this.latest_text;
				slice.startingcharacter = 0;
				this.substringArray.push(slice);
			}

			this.rerenderHighlights();
		},

		// this is a helper function that helps decide at which character indices to break the text into substrings
		assignRelevantCharacters() {
			// console.log(this.associations)
			Object.values(this.associations).forEach((element) => {
				// for each character substring
				for (let i = 0; i < element.length; i++) {
					let startCharacter = parseInt(element[i].split("-")[0]);
					let endCharacter = parseInt(element[i].split("-")[1]) + 1;
					this.relevantCharacters.push(startCharacter);
					this.relevantCharacters.push(endCharacter);
				}
			});
			// console.log(Object.keys(this.associations))
			Object.keys(this.associations).forEach((element) => {
				// for each character substring
				for (let i = 0; i < element.length; i++) {
					let startTimestamp = parseFloat(element.split("-")[0]).toFixed(2);
					let endTimestamp = parseFloat(element.split("-")[1]).toFixed(2);
					this.relevantTimestamps.push(Number(startTimestamp));
					this.relevantTimestamps.push(Number(endTimestamp));
				}
			});
			this.relevantTimestamps.push(0);
			this.relevantTimestamps.push(this.$store.state.audioDuration); // i probably need to divide this by some multiple of 10 though FLAG

			this.relevantTimestamps.sort((a, b) => a - b);
			this.relevantTimestamps = [...new Set(this.relevantTimestamps)];
		},


		populateSRT(startingcharacter) {
			let tempassociation = {};
			for (let i = 0; i < this.parsedAssociations.length; i++) {
				if (
					startingcharacter >= this.parsedAssociations[i].startCharacter &&
					startingcharacter < this.parsedAssociations[i].endCharacter
				) {
					tempassociation.startCharacter =
						this.parsedAssociations[i].startCharacter;
					tempassociation.endCharacter =
						this.parsedAssociations[i].endCharacter;
					tempassociation.startTime = String(
						this.parsedAssociations[i].startTime
					);
					tempassociation.endTime = String(this.parsedAssociations[i].endTime);
					break;
				}
			}
			return tempassociation;
		},

		snapToTimestamp(substring) {
			// console.log(substring)
			let startingcharacter = substring.startingcharacter;
			// let text=substring.text
			// console.log(startingcharacter);
			// console.log(text)
			let potentialSnapArray = [];
			this.parsedAssociations.forEach((element) => {
				if (
					startingcharacter >= element.startCharacter &&
					startingcharacter < element.endCharacter
				) {
					potentialSnapArray.push(element);
					// console.log(element)
				}
			});
			potentialSnapArray.sort(
				(a, b) =>
					b.endCharacter -
					b.startCharacter -
					(a.endCharacter - a.startCharacter)
			);
			let playFromTimestamp =
				potentialSnapArray[potentialSnapArray.length - 1].startTime / 100;
			// console.log(playFromTimestamp)
			if (playFromTimestamp || playFromTimestamp === 0) {
				// console.log(playFromTimestamp)
				// console.log(startingcharacter);
				// console.log(text)
				let params = {
					timestamp: playFromTimestamp,
					timestampEnd:
						potentialSnapArray[potentialSnapArray.length - 1].endTime / 100,
					// "text": text
				};

				this.$store.commit("updateIncomingCurrentTime", params);
			}
			potentialSnapArray.length = 0;
		},
	},
};
</script>

<style scoped>
.beautiful::-webkit-scrollbar {
	width: 14px;
}

/* Scrollbar thumb (the draggable part of the scrollbar) */
.beautiful::-webkit-scrollbar-thumb {
	background-color: #be185d;
	/* #ede9fe */
	/* pink 700 */
	border-radius: 7px;
	/* Rounded edges */
	border: 4px solid transparent;
	/* #f0f0f0 */
	background-clip: content-box;
}


/* .beautiful::before { */
/* background-clip:content-box; */
/* background-color: #fafafa;  */
/* zinc 50 */
/* background-color:red; */

/* } */

.beautiful {
	background-color: transparent;
	font-weight: 500;
	/* overflow-y:scroll; */
	/* height: 77.5vh; */
	width: 100%;
	font-family: 'Lora', serif;
	/* Quicksand, Lora, Roboto Slab */
	font-size: 1.2em;
	/* color: #3b3a36 ;   1a1e3d 3b3a36 */
	padding-left: 1.4vw;
	padding-right: 1.4vw;
	/* padding-top: 20px; */
	padding-bottom: 40px;
	margin-right: 20px;
	margin-bottom: 20px;
	margin-top: 10px;
	/* background-color: #f2f4f9       ;  f2f4f9 #e7f1fb or #dceefb */
	/* background-color: #f9f9f9; */
	border-radius: 16px;
	/* font-style: italic; */
}

.clear {
	font-weight: 600;
	padding-left: 15px;
}

.clear:hover {
	padding-left: 15px;
}

.clear:active {
	transform: translateY(.3px);
}
</style>