<template>
	<div class="w-full ">
		
		<div class="w-full grow">
			<!-- this component will depend on the user's selection of component to vue via a menu in a different component -->
			<Studio  :audio_id="audio_id" :timestep="phraseLength" :scribing="scribing"
				:studying="phraseLength" :fontsize="fontsize" :key="prompterReloadCounter"
				:clearTimestampsvar="clearTimestampsvar" :updateAssociations="updateAssociations"
				:saveEditscounter="saveEditscounter" :newPromptscounter="newPromptscounter"
				:resetSensitivitycounter="resetSensitivitycounter" :downloadSRTcounter="downloadSRTcounter"
				:newPhrasescounter="newPhrasescounter" :interpretationStatus="interpretationStatus"
				:interpretation_id="interpretation_id" :editingversion="interpretationFull.version"
				:playingbeginning="playingBeginning" 
				@permanentlydestroy="permanentlydestroy($event)" @increasePhrasesCounter="newPhrase()"
				@generateNewPrompt="newPrompt()" 
				@updateTitleLanguage="updateTitleLanguagefunc($event)" @increaseDifficulty="increaseLevel()"
				>

		</Studio>


		</div>
		<!-- <div class="text-sm pb-[2vh] font-medium cursor-pointer  justify-self-center" @click="visitDito()">Click-Text created by Yakalo Collective (patent pending)</div> -->
	</div>
</template>

<script>
// import SyncingModal from "@/components/SyncingModal.vue";
// import Viewer from "@/comparecomponents/ViewerCompare.vue";
// import Editor from "@/components/Editor.vue";
// import Tagger from "@/components/Tagger.vue";
// import Prompter from "@/components/Prompter.vue";
import Studio from "@/comparecomponents/StudioCompare.vue";
// import StorybookStyleMenu from "@/displaycomponents/StorybookStyleMenuDisplay.vue";
// import SelectInterpretationMenu from "@/components/SelectInterpretationMenu.vue";
// import DeleteInterpretationViewer from "@/components/DeleteInterpretationViewer.vue";

export default {
	name: "SecondInterpretationCompare",
	components: {
		// Editor,
		// Viewer,
		// Tagger,
		// SyncingModal,
		// Prompter,
		Studio,
		// StorybookStyleMenu,
		// SelectInterpretationMenu,
		// DeleteInterpretationViewer,
	},

	data: () => {
		return {
			tier: process.env.VUE_APP_TIER,
			timestep: 0,
			scribing: 50,
			scribingold: 50,
			studying: 0,
			studyingold: 0,
			fontsize: 16,
			updateAssociations: 0,
			clearTimestampsvar: 0,
			newPromptscounter: 0,
			resetSensitivitycounter: 0,
			downloadSRTcounter: 0,
			newPhrasescounter: 0,
			// submitcounter: 0,
			saveEditscounter: 0,
			// clearOldTimestampsvar: 0,
			interpretationStatus: "", // this remembers whether the currently logged-in user is a viewer, editor, or owner of the currently-displayed interpretation
			// styleoption: "Viewer", // this can be Viewer, Editor, or Tagger, depending on how the user is currently interacting with the displayed interpretation
			interpretationFull: {}, // this contains all of the information about the currently displayed interpretation
			showSyncingModal: false,
			prompterReloadCounter: 0, // reloads prompter component



			// studioStillRestarting: false,
			// studioAssignPrompt: 0,

		};
	},
	watch: {


		reloadStudioCounter: function () {
			this.prompterReloadCounter++
			this.newPhrasescounter = 0
		},
	},
	props: {
		reloadStudioCounter: 0,
		playingBeginning: 0,
		styleoption: "Viewer",
		phraseLength: 20,
		audio_id: {
			default: "",
		},
		interpretation_id: {
			default: "",
		},
		// list of interpretations to be displayed in the dropdown menu
		interpretationsList: {
			default: [],
		},

		// list of interpretations currently displayed in columns in the viewer
		formerInterpretationsList: {
			default: [],
		},
	},
	computed: {},

	mounted() {
		// get all the information about the interpretation displayed in this particular column in the browser
		this.interpretationFull = this.formerInterpretationsList.filter(
			(element) => element.id == this.interpretation_id
		)[0];

		// call a function to identify whether the currently logged-in user is a viewer, editor, or owner of the interpretation displayed in this column in the browser
		this.sharingSetting(
			this.interpretationFull.created_by.user_ID,
			this.interpretationFull.shared_editors,
			this.interpretationFull.shared_viewers,
			this.interpretationFull.public
		);
	},

	unmounted() {
		if (this.$store.state.prompterID == this.interpretation_id) {
			this.$store.commit("removePrompterID");
		}
	},
	methods: {




		// createEmbeddable() {
		// 	const routeData = this.$router.resolve({
		// 		name: "Display",
		// 		params: { audio_ID: this.audio_id },
		// 		query: { open: this.interpretation_id },
		// 	});
		// 	window.open(routeData.href, '_blank');
		// },

		toggleSyncingModal() {
			this.showSyncingModal = !this.showSyncingModal;
		},
		closeSyncingModal() {
			this.showSyncingModal = false;
		},
		clearTimestamps() {
			this.clearTimestampsvar++;
		},
		// clearOldTimestamps() {
		// 	this.clearOldTimestampsvar++;
		// },
		saveEditsincrease() {
			this.saveEditscounter++;
		},

		changescribinglength() { this.scribing = this.scribingold },

		changestudyinglength() { this.studying = this.studyingold },

		increaseLevel() {
			this.studyingold = this.studying + 40
			this.changestudyinglength()
		},

		downloadSRT() {
			this.downloadSRTcounter++;
		},
		// 		submitincrease() {
		// 	this.submitcounter++;
		// },
		newPrompt() {
			this.newPromptscounter++;
		},
		resetSensitivity() {
			this.resetSensitivitycounter++;
		},
		newPhrase() {
			this.newPhrasescounter++;
		},
		updateAssociationsfunc() {
			this.updateAssociations++;
		},
		// toggleStorybookStylefunction(styleselection) {
		// 	this.styleoption = styleselection;
		// 	if (this.styleoption == "Prompter" || this.styleoption == "Studio") {
		// 		this.$store.commit("updatePrompterID", this.interpretation_id);
		// 	} else if (
		// 		this.styleoption != "Prompter" &&
		// 		this.styleoption != "Studio" &&
		// 		this.$store.state.prompterID == this.interpretation_id
		// 	) {
		// 		this.$store.commit("removePrompterID");
		// 	}
		// 	this.$emit("toggleStorybookStyle", this.styleoption);
		// },

		sharingSetting(owner, editors, viewers, publictf) {
			if (this.$store.state.user !== null) {
				if (owner == this.$store.state.user.uid) {
					this.interpretationStatus = "owner";
				} else if (
					editors &&
					editors
						.map((item) => item.user_ID)
						.includes(this.$store.state.user.uid)
				) {
					this.interpretationStatus = "editor";
				} else if (
					(viewers &&
						viewers
							.map((item) => item.user_ID)
							.includes(this.$store.state.user.uid)) ||
					publictf == true
				) {
					this.interpretationStatus = "viewer";
				}
			} else if (publictf == true) {
				this.interpretationStatus = "viewer";
			}
		},

		// when the user chooses to swap the interpretation they are currently viewing for a different interpretation...
		changeInterpretationIDfunction(newID) {
			let formerinterpretation = this.interpretation_id;
			// this.$emit("displayInterpretationID", newID); // ...tell the parent component to create a new column for the new interpretation the user wants to view
			// this.returnFormerInterpretation(formerinterpretation); // ...tell the parent component to remove this column currently being worked in
			this.$emit("exchangeInterpretations", { newID, formerinterpretation })

		},

		returnFormerInterpretation(formerinterpretation) {
			this.$emit("returnFormerInterpretation", formerinterpretation);
		},

		permanentlydestroy(formerinterpretation) {
			this.$emit("permanentlydestroy", formerinterpretation);
		},

		updateTitleLanguagefunc({ id, title, language }) {
			// console.log("right there")
			this.$emit("updateTitleLanguage", { "id": id, "title": title, "language": language })
		},
	},
};
</script>

<style scoped>
.tibetan {
	font-size: 1.25rem
		/* 14px */
	;
	line-height: 1.75rem
		/* 20px */
	;
}

.nottibetan {
	font-size: 0.875rem
		/* 14px */
	;
	line-height: 1.25rem
		/* 20px */
	;
}

@media (min-width: 768px) {
	.nottibetan {
		font-size: 1rem
			/* 16px */
		;
		line-height: 1.5rem
			/* 24px */
		;
	}

	.tibetan {
		font-size: 1.5rem
			/* 14px */
		;
		line-height: 2rem
			/* 20px */
		;
	}
}

.tibetantiny {
	font-size: 1.125rem
		/* 14px */
	;
	line-height: 1.75rem
		/* 20px */
	;
}

.nottibetantiny {
	font-size: 0.75rem
		/* 14px */
	;
	line-height: 1rem
		/* 20px */
	;
}
</style>
