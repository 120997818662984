<template>
	<div class="flex w-full font-medium rounded beautiful bg-zinc-50 text-zinc-600">
		<div class="flex-col w-full">
			<!-- <div class="flex fixed pr-[31.15vw] z-40 text-[1rem] mt-[2vh]  text-yellow-400 justify-end  w-full clear  ">
				<button class="hover:text-yellow-600 hover:underline" @click="toggleOptions()">More Options</button>
			</div> -->

			<!-- <div
				class="flex fixed pr-[31.15vw] z-40 text-[3rem] -mt-[24.5px] text-yellow-400 justify-end  w-full clear  ">
				<button class="hover:text-yellow-600" @click="toggleOptions()">&hellip;</button>
			</div> -->
			<p class="flex   mt-[24.5px] font-semibold text-rose-900">Click the phrase that best matches the audio:</p>
			<div class="flex pl-[1.6vw] w-full  pt-[30px] border-gray-300 rounded">

				<div class="-mt-[0px] pb-[0px]" v-if="substringArray.length > 0">
					<span class="flex flex-col">
						<p v-if="phrasechoicesArray[0]" class="pb-3 " :class="{ wronganswer: answer1wrong }" style="display:inline"><span
							:class="{ 'cursor-pointer': !answer1wrong, 'cursor-default': answer1wrong }" @click="chooseanswer(phrasechoicesArray[0], 1)">
								1. {{ phrasechoicesArray[0] }}</span></p>
						<p v-if="phrasechoicesArray[1]" class="pb-3 " :class="{ wronganswer: answer2wrong }" style="display:inline"><span
							:class="{ 'cursor-pointer': !answer2wrong, 'cursor-default': answer2wrong }" @click="chooseanswer(phrasechoicesArray[1], 2)">
								2. {{ phrasechoicesArray[1] }}</span></p>
						<p v-if="phrasechoicesArray[2]" class="pb-3 " :class="{ wronganswer: answer3wrong }" style="display:inline"><span
							:class="{ 'cursor-pointer': !answer3wrong, 'cursor-default': answer3wrong }" @click="chooseanswer(phrasechoicesArray[2], 3)">
								3. {{ phrasechoicesArray[2] }}</span></p>
						<p v-if="phrasechoicesArray[3]" class="pb-3 " :class="{ wronganswer: answer4wrong }" style="display:inline"><span
							:class="{ 'cursor-pointer': !answer4wrong, 'cursor-default': answer4wrong }" @click="chooseanswer(phrasechoicesArray[3], 4)">
								4. {{ phrasechoicesArray[3] }}</span></p>
					</span>
				</div>
			</div>


			<div class="flex flex-col  w-full px-[.7vw]  text-black">

				<p class=" pt-[42px]  justify-center text-center w-full font-medium  endtext flex ">Jump to
					prompt&nbsp;<input class="pl-2 border rounded border-zinc-600" @keyup.enter="jumpToSubstring()"
						v-model="promptNumber" size="1" />&nbsp;/ {{
							substringArray.length }}

				</p>


			</div>

			<!-- <textarea class="w-full h-full px-3 py-1 border-gray-300 rounded studio"
			:style="{ 'font-size': fontsize + 'px' }" style="overflow: scroll; height:22vh;"
			placeholder="enter new text here" v-model="new_text_unstripped" ref="promptertextarea"></textarea> -->
			<!-- <div class="flex flex-row justify-center w-full" v-if="finished == true">
			<div><button @click="increaseLevel()" class="border-sky-600 bg-sky-700 hover:bg-sky-600 dropbtn">Increase
					Difficulty</button></div>
		</div> -->
		</div>
	</div>
</template>

<script>
import { getIdToken } from "firebase/auth";

export default {
	inheritAttrs: false,
	name: "Studio",
	data: () => {
		return {
			new_text_unstripped: "",
			language_name: "",
			title: "",
			latest_text: "",
			phrasechoicesArray: [],
			substringindex: null,
			// finished: false, // make this false to hide the Increase Difficulty button until one level completed
			spaced_by: "",
			associations: null,
			parsedAssociations: [], // array of objects that each indicates which range of characters should be highlighted within a given range of milliseconds
			substringArray: [], // array of objects that each includes a substring of the displayed text, with the index of the substring's starting character
			startslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			endslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			i: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			watchSubstringArray: 0, // helper for rerendering DOM
			watchSubstringIndex: 0,
			substringArrayUpdatingStill: false,
			promptNumber: 1, // references the prompt the user is working on currently
			answer1wrong: false,
			answer2wrong: false,
			answer3wrong: false,
			answer4wrong: false,
		};
	},
	computed: {
		regexwithPromptNumber() {
			if (this.promptNumber != "") {

				return new RegExp(`${this.escapeRegex(this.promptNumber).replaceAll(/[Iıİi]/g, '[Iıİi]')}+`, "ugi");
			} else {
				return "";
			}
		},

		new_text() {
			if (this.spaced_by != "") {
				let stripped = this.new_text_unstripped.replace(
					this.regexwithmultiplespacedby,
					this.spaced_by
				);
				return stripped;
			} else if (this.spaced_by == "") {
				return this.new_text_unstripped;
			}
		},

		scribingclean() {
			if (this.$store.state.audioDuration < this.studying) {
				return this.$store.state.audioDuration;
			} else {
				return this.studying;
			}
		},

		regexwithspacedby() {
			return new RegExp(`${this.escapeRegex(this.spaced_by)}|(\n)`);
		},

		regexwithmultiplespacedby() {
			return new RegExp(`${this.escapeRegex(this.spaced_by)}+`, "ug");
		},
	},
	props: {
		// ID of associated audio file
		audio_id: {
			default: "",
		},
		studying: {
			default: 0,
		},
		newPhrasescounter: {
			default: 0,
		},
		fontsize: { default: 12 },

		// ID of interpretation displayed in this Editor
		interpretation_id: { default: "" },

		// whether the interpretation is owned "owner", editable "editor", or only viewable "viewer" by the logged-in user
		interpretationStatus: { default: "" },
	},
	watch: {
		// finished: function () {
		// 	if (this.finished == true) {
		// 		this.promptNumber = 1
		// 		this.substringindex = 0
		// 		this.watchSubstringIndex++

		// 	}
		// },

		interpretationStatus: function () {
			if (this.interpretationStatus && (this.scribingclean > 0)) {
				this.fetchNewInterpretation();
			}
		},

		scribingclean: function () {
			if (this.interpretationStatus && (this.scribingclean > 0)) {
				this.fetchNewInterpretation();
			}
		},

		new_text: function () {
			if (this.substringArray.length >= 1) {
				if (this.new_text == this.substringArray[this.substringindex].text) {
					this.new_text_unstripped = "";
					this.$emit("increasePhrasesCounter");
				} else {this.$emit("replay")}
			}
		},

		watchSubstringArray: function () {
			this.substringArrayUpdatingStill = false;
			if (
				this.substringArray.length > 0 &&
				this.$store.state.audioDuration > 0
			) {
				this.substringindex = 0; // FLAG
				this.promptNumber = 1
				this.watchSubstringIndex++;
				// this.$emit("StudioRendered")
			}
		},

		"$store.state.audioDuration": function () {
			if (
				this.substringArray.length > 0 &&
				this.$store.state.audioDuration > 0
			) {
				this.substringindex = 0;
				this.promptNumber = 1;
				this.watchSubstringIndex++;
			}
		},

		newPhrasescounter: function () {
			if (
				this.substringArray.length > 0 &&
				this.$store.state.audioDuration > 0
			) {
				if (this.newPhrasescounter == 0 || (this.substringindex == this.substringArray.length - 1)) {
					this.substringindex = 0;
					this.promptNumber = 0
					this.watchSubstringIndex++;
				}
				else
					if (this.substringindex < this.substringArray.length - 1) {
						this.substringindex++;
						this.watchSubstringIndex++;
					}
				this.new_text_unstripped = "";
			}
			this.promptNumber++
		},

		watchSubstringIndex: function () {
			this.answer1wrong=false
			this.answer2wrong=false
			this.answer3wrong=false
			this.answer4wrong=false
			//if the audio player has loaded and the text has been divided into substrings already, then focus on the textbox
			if (
				this.$store.state.audioDuration > 0 &&
				this.substringArray.length > 0
			) {
				// console.log(this.substringArray[this.substringindex].text);
				this.phrasechoicesArray = [];
				//populate answers array


				this.phrasechoicesArray = [this.substringArray[this.substringindex].text]
				let tempSubstringArray = [...this.substringArray]
				tempSubstringArray.splice(this.substringindex, 1)

				while (this.phrasechoicesArray.length < 4 && tempSubstringArray.length > 0) {
					let randomIndex = Math.floor(Math.random() * this.substringArray.length)
					let falseAnswer = this.substringArray[randomIndex].text

					if (!this.phrasechoicesArray.includes(falseAnswer)) { this.phrasechoicesArray.push(falseAnswer) }
					tempSubstringArray.splice(randomIndex, 1)
				}

				this.$store.commit(
					"updateStartTimePrompter",
					this.substringArray[this.substringindex].starttime / 100
				);
				this.$store.commit(
					"updateEndTimePrompter",
					this.substringArray[this.substringindex].endtime / 100
				);

				this.$store.commit("forceRegionRerender");

				// randomize answers array
				for (let j = this.phrasechoicesArray.length - 1; j > 0; j--) {
					var h = Math.floor(Math.random() * (j + 1));
					var temp = this.phrasechoicesArray[j];
					this.phrasechoicesArray[j] = this.phrasechoicesArray[h];
					this.phrasechoicesArray[h] = temp;
				}

				// this.$refs.promptertextarea.focus();
			}
		},
	},
	methods: {
		jumpToSubstring() {
			if (this.promptNumber > 0 && this.promptNumber <= this.substringArray.length) {
				// this.$store.commit("setSubstringToLoad", this.promptNumber)
				this.substringindex = this.promptNumber - 1
				this.watchSubstringIndex++
			}
		},

		// toggleOptions() {
		// 	this.$store.commit("toggleOptions")
		// },
		increaseLevel() {
			this.$emit("increaseDifficulty")
		},

		chooseanswer(answer, index) {
			this.new_text_unstripped = answer;
			if (index==1) {this.answer1wrong=true}
			else if (index==2) {this.answer2wrong=true}
			else if (index==3) {this.answer3wrong=true}
			else if (index==4) {this.answer4wrong=true}
			// if (
			// 	this.interpretationStatus == "owner" ||
			// 	this.interpretationStatus == "editor"
			// ) {
			// 	this.new_text_unstripped = answer;
			// }
		},

		escapeRegex: function (string) {
			return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
		},

		async fetchNewInterpretation() {


			this.new_text_unstripped = "";
			this.phrasechoicesArray = [];
			this.substringindex = null;
			this.watchSubstringIndex++;
			this.associations = null;
			this.parsedAssociations = [];
			this.substringArray = [];

			fetch(
				process.env.VUE_APP_api_URL +
				"interpretations/" +
				this.interpretation_id +
				"/audio/" +
				this.audio_id +
				"/" +
				this.interpretationStatus +
				"/",
				{
					method: "GET",

					headers: {
						"Content-Type": "application/json",

						Authorization: this.$store.state.idToken,
					},
				}
			)
				.then((response) => response.json())
				.then((data) => {
					this.title = data.interpretation.title;
					this.language_name = data.interpretation.language_name;
					this.latest_text = data.interpretation.latest_text;
					this.spaced_by = data.interpretation.spaced_by;
				})

				// access the information about what to highlight, and when, for the interpretation that is to be displayed
				.then(() => {
					// console.log(this.scribingclean)
					if (this.scribingclean < 80) {
						// console.log("short")
						this.substringArray = this.$store.state.substringArrayShort
					}
					else if (this.scribingclean >= 80) {
						// console.log("long")
						this.substringArray = this.$store.state.substringArrayLong
					}
					// console.log(this.substringArray)
					this.watchSubstringArray++;

				})

				.catch((error) => console.error("Error:", error));
		},
	},

	mounted() {
		if (this.interpretationStatus && (this.scribingclean > 0)) {
			this.fetchNewInterpretation();
		}
	},
};
</script>


<style scoped>
.studio {
	-ms-overflow-style: none;
	/* for Internet Explorer, Edge */
	scrollbar-width: none;
	/* for Firefox */
	overflow-y: scroll;
}



.studio::-webkit-scrollbar {
	display: none;
	/* for Chrome, Safari, and Opera */
}

.endtext {
	font-family: 'Lora', serif;

}

.beautiful {
	font-family: 'Lora', serif;
	/* Quicksand, Lora, Roboto Slab */
	font-size: 1.2em;
	/* color: #333; */
	padding-left: 1.4vw;
	padding-right: 1.4vw;
	/* padding-top: 20px; */
	padding-bottom: 40px;
	margin-right: 20px;
	/* margin-left: 20px; */
	margin-bottom: 20px;
	margin-top: 10px;
	/* background-color: #f9f9f9; */
	/* font-style: italic; */
	border-radius: 16px;
}


.clear {
	font-weight: 600;
	padding-left: 15px;
}

.clear:hover {
	padding-left: 15px;
}

.clear:active {
	transform: translateY(.3px);
}

.wronganswer {
	text-decoration: line-through;
	cursor: default;
	color: #6b7280;
	font-weight: 300;
}
</style>