<template>
	<div>

	</div>
</template>

<script>
import { getIdToken } from "firebase/auth";

export default {
	name: "StudioLogic",
	data: () => {
		return {
			new_text_unstripped: "",
			language_name: "",
			title: "",
			latest_text: "",
			phrasechoicesArray: [],
			substringindex: null,
			// finished: false, // make this false to hide the Increase Difficulty button until one level completed
			spaced_by: "",
			associations: null,
			parsedAssociations: [], // array of objects that each indicates which range of characters should be highlighted within a given range of milliseconds
			substringArray: [], // array of objects that each includes a substring of the displayed text, with the index of the substring's starting character
			startslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			endslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			i: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			watchSubstringArray: 0, // helper for rerendering DOM
			watchSubstringIndex: 0,
			// substringArrayUpdatingStill: false,
			promptNumber: 1, // references the prompt the user is working on currently
		};
	},

	props: {
		// ID of associated audio file
		audio_id: {
			default: "",
		},
		studying: {
			default: 200,
		},
		newPhrasescounter: {
			default: 0,
		},
		fontsize: { default: 12 },

		// ID of interpretation displayed in this Editor
		interpretation_id: { default: "" },

		// whether the interpretation is owned "owner", editable "editor", or only viewable "viewer" by the logged-in user
		interpretationStatus: { default: "" },
	},
	watch: {
		interpretationStatus: function () {
			this.fetchNewInterpretation();
		},
		watchSubstringArray: function () {
			if (
				this.substringArray.length > 0
			) {
				if (this.studying < 80){
				this.$store.commit(
					"updateStudioShort",
					this.substringArray
				);
		// 	console.log("short is:")
		// console.log(this.$store.state.substringArrayShort)
	
	}
				if (this.studying >= 80){
				this.$store.commit(
					"updateStudioLong",
					this.substringArray
				);
				// console.log("long is:")
				// console.log(this.$store.state.substringArrayLong)
			}
			}
		},
	},
	methods: {

		escapeRegex: function (string) {
			return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
		},

		async fetchNewInterpretation() {
			if (this.$store.state.user) {
				// REFRESH ID TOKEN FIRST AND WAIT FOR IT
				await getIdToken(this.$store.state.user)
					.then((idToken) => {
						this.$store.commit("SetIdToken", idToken);
						// console.log(this.$store.state.idToken)
					})
					.catch((error) => {
						// An error happened.
						console.log("Oops. " + error.code + ": " + error.message);
					});
			}

			this.new_text_unstripped = "";
			this.phrasechoicesArray = [];
			this.substringindex = null;
			this.watchSubstringIndex++;
			this.associations = null;
			this.parsedAssociations = [];
			this.substringArray = [];
			

			fetch(
				process.env.VUE_APP_api_URL +
				"interpretations/" +
				this.interpretation_id +
				"/audio/" +
				this.audio_id +
				"/" +
				this.interpretationStatus +
				"/",
				{
					method: "GET",

					headers: {
						"Content-Type": "application/json",

						Authorization: this.$store.state.idToken,
					},
				}
			)
				.then((response) => response.json())
				.then((data) => {
					this.title = data.interpretation.title;
					this.language_name = data.interpretation.language_name;
					this.latest_text = data.interpretation.latest_text;
					this.spaced_by = data.interpretation.spaced_by;
				})

				// access the information about what to highlight, and when, for the interpretation that is to be displayed
				.then(() => {
					// console.log(this.scribingclean)

					fetch(
						process.env.VUE_APP_api_URL +
						"content/" +
						this.audio_id +
						"/" +
						this.interpretation_id +
						"/" +
						this.studying + // FLAG TIME DECISION
						"/", // timestep is 200 hundredths of seconds
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",

								Authorization: this.$store.state.idToken,
							},
						}
					)
						.then((response) => response.json())
						.then((data) => {
							this.associations = data.associations;
						})
						.then(() => this.parsed_associations()) // turn the highlighting information from backend into something usable
						.then(() => {
							this.latest_text_slices(); // split up the displayed text into substrings to be highlighted whenever necessary
						})
						.catch((error) => console.error("Error:", error));
				})

				.catch((error) => console.error("Error:", error));
		},

		parsed_associations: function () {
			this.parsedAssociations.length = 0;
			let parsedAssociations2 = []
			let parsedAssociations3 = []
			let parsedAssociations4 = []
			let parsedHelper = []
			let parsedHelper2 = []
			if (this.associations) {
				for (let i = 0; i < Object.keys(this.associations).length; i++) {
					let startTime = parseFloat(Object.keys(this.associations)[i].split("-")[0]).toFixed(2);
					let endTime = parseFloat(Object.keys(this.associations)[i].split("-")[1]).toFixed(2);
					let intervalsCount = Object.values(this.associations)[i].length;

					for (let j = 0; j < intervalsCount; j++) {
						let startCharacter = Object.values(this.associations)[i][j].split(
							"-"
						)[0];
						let endCharacter = Object.values(this.associations)[i][j].split(
							"-"
						)[1];

						let associationsObject = {};
						associationsObject.startTime = Number(startTime);
						associationsObject.endTime = Number(endTime);

						associationsObject.startCharacter = Number.parseInt(startCharacter);
						associationsObject.endCharacter = Number.parseInt(endCharacter) + 1;
						parsedAssociations2.push(associationsObject);
					}
				}
			}
			parsedAssociations2.sort((a, b) => b.startCharacter - a.startCharacter);
			// console.log(parsedAssociations2)
			parsedAssociations2.forEach((parsed2) => {
				parsedAssociations4.forEach((parsed1) => {
					if (parsed2.endCharacter >= parsed1.endCharacter) {
						parsed2.startTime = Math.min(parsed2.startTime, parsed1.startTime)
						parsed2.endTime = Math.max(parsed2.endTime, parsed1.endTime)
					}

				})
				parsedAssociations4.unshift(parsed2)
			}




			)




			parsedAssociations4.sort((a, b) => b.startTime - a.startTime);
			// console.log(...parsedAssociations3)

			//if the timestamps nest, then include all of the characters of both.
			parsedAssociations4.forEach((parsed4) => {
				parsedAssociations3.forEach((parsed3) => {
					if (parsed4.endTime >= parsed3.endTime && !(parsed4.startCharacter <= parsed3.startCharacter && parsed3.endCharacter <= parsed4.endCharacter)) {
						parsed4.startCharacter = Math.min(parsed4.startCharacter, parsed3.startCharacter)
						parsed4.endCharacter = Math.max(parsed4.endCharacter, parsed3.endCharacter)
					}

				})
				parsedAssociations3.unshift(parsed4)
			}
			)







			parsedAssociations3.sort((a, b) => b.startTime - a.startTime);
			// console.log(...parsedAssociations3)

			//if the timestamps are offset, then the longer one should be completely expanded to include the other's timestamp and character ranges too.
			parsedAssociations3.forEach((parsed3) => {
				this.parsedAssociations.forEach((parsed) => {
					// if (parsed3.endTime >= parsed.endTime && !(parsed3.startCharacter <= parsed.startCharacter && parsed.endCharacter <= parsed3.endCharacter)) {

					if ((parsed3.startTime <= parsed.startTime && parsed3.endTime > parsed.startTime && parsed3.endTime <= parsed.endTime) || (parsed3.startTime >= parsed.startTime && parsed3.startTime < parsed.endTime && parsed3.endTime >= parsed.endTime)) {
						if ((parsed3.endTime - parsed3.startTime) >= (parsed.endTime - parsed.startTime)) {
							parsed3.startCharacter = Math.min(parsed3.startCharacter, parsed.startCharacter)
							parsed3.endCharacter = Math.max(parsed3.endCharacter, parsed.endCharacter)
							parsed3.startTime = Math.min(parsed3.startTime, parsed.startTime)
							parsed3.endTime = Math.max(parsed3.endTime, parsed.endTime)
						}

						else {
							// expand parsed and put it back in
							parsedHelper.unshift(parsed)
							parsedHelper2.unshift(parsed3)
						}
					}


				})




				this.parsedAssociations.unshift(parsed3)
			}
			)
			// console.log(...this.parsedAssociations)
			// console.log(...parsedHelper)
			// console.log(...parsedHelper2)
			for (let i = 0; i < parsedHelper.length; i++) {
				// expand the thing at that index to include the coindexed entry of parsedHelper2
				// Done!
				// console.log(parsedHelper[i])
				let index = this.parsedAssociations.indexOf(parsedHelper[i])
				this.parsedAssociations[index].startTime = Math.min(parsedHelper[i].startTime, parsedHelper2[i].startTime)
				this.parsedAssociations[index].endTime = Math.max(parsedHelper[i].endTime, parsedHelper2[i].endTime)
				this.parsedAssociations[index].startCharacter = Math.min(parsedHelper[i].startCharacter, parsedHelper2[i].startCharacter)
				this.parsedAssociations[index].endCharacter = Math.max(parsedHelper[i].endCharacter, parsedHelper2[i].endCharacter)
				// console.log(this.parsedAssociations[index])
			}

			// console.log(...this.parsedAssociations)

		},

		latest_text_slices() {
			this.substringArray = [];
			if (this.parsedAssociations.length >= 1) {
				// console.log("here")
				this.i = 0;
				while (this.i + 1 <= this.parsedAssociations.length) {
					let slice = {};
					this.startslice = this.parsedAssociations[this.i].startCharacter;
					this.endslice = this.parsedAssociations[this.i].endCharacter;
					slice.starttime = this.parsedAssociations[this.i].startTime;
					slice.endtime = this.parsedAssociations[this.i].endTime;
					slice.text = this.latest_text.substring(
						this.startslice,
						this.endslice
					);
					slice.startingcharacter = this.startslice;
					this.substringArray.push(slice);
					this.i++;
				}
				// console.log(...this.substringArray)
				let indicesToDelete = [];
				// delete the ones whose timestamps completely surround another one
				let y = 0;
				while (y < this.substringArray.length) {
					// this is the one that we are checking against
					let checkExteriorStart = Number(parseFloat(this.substringArray[y].starttime).toFixed(2))
					let checkExteriorEnd = Number(parseFloat(this.substringArray[y].endtime).toFixed(2))
					let z = 0;
					while (z < this.substringArray.length) {
						let checkInteriorStart = Number(parseFloat(this.substringArray[z].starttime).toFixed(2))
						let checkInteriorEnd = Number(parseFloat(this.substringArray[z].endtime).toFixed(2))

						if (
							// if this one is exterior to the other one, then delete it
							y != z &&
							((checkInteriorEnd >= checkExteriorEnd &&
								checkInteriorStart <= checkExteriorStart) ||
								(checkInteriorEnd - checkInteriorStart >=
									checkExteriorEnd - checkExteriorStart &&
									((checkInteriorStart < checkExteriorStart &&
										checkInteriorEnd > checkExteriorStart &&
										checkInteriorEnd < checkExteriorEnd) ||
										(checkInteriorStart > checkExteriorStart &&
											checkInteriorStart < checkExteriorEnd &&
											checkInteriorEnd > checkExteriorEnd))))
						) {
							// console.log(this.substringArray[y])
							// 	console.log(this.substringArray[z])
							if (this.studying < 80) {
								indicesToDelete.push(z);

								// console.log("deleting second of above")
							}
							else if (this.studying >= 80) {
								indicesToDelete.push(y);
							}
						} else if (
							// if this one is interior to the other one, then break this while loop, delete the other one and do not index the other one's loop
							y != z &&
							(checkInteriorEnd <= checkExteriorEnd &&
								checkInteriorStart >= checkExteriorStart)
						) {
							// console.log(this.substringArray[y])
							// 	console.log(this.substringArray[z])
							if (this.studying < 80) {

								// delete exterior one
								// console.log("deleting first of above")
								indicesToDelete.push(y);
							}
							else if (this.studying >= 80) {
								indicesToDelete.push(z);
							}
							break;
						}

						z++;
					}
					y++;
				}
				// console.log(indicesToDelete)
				indicesToDelete = [...new Set(indicesToDelete)];
				// console.log(indicesToDelete)
				for (let t = indicesToDelete.length - 1; t >= 0; t--) {
					this.substringArray.splice(indicesToDelete[t], 1);
				}

			}

this.watchSubstringArray++
		},
	},

	mounted() {
		this.parsedAssociations.length = 0;
		if (this.interpretationStatus) {
			
			this.fetchNewInterpretation();
		}
	},
};
</script>


<style scoped>
.studio {
	-ms-overflow-style: none;
	/* for Internet Explorer, Edge */
	scrollbar-width: none;
	/* for Firefox */
	overflow-y: scroll;
}



.studio::-webkit-scrollbar {
	display: none;
	/* for Chrome, Safari, and Opera */
}

.endtext {
	font-family: 'Lora', serif;

}

.beautiful {
	font-family: 'Lora', serif;
	/* Quicksand, Lora, Roboto Slab */
	font-size: 1.2em;
	/* color: #333; */
	padding-left: 1.4vw;
	padding-right: 1.4vw;
	/* padding-top: 20px; */
	padding-bottom: 40px;
	margin-right: 20px;
	/* margin-left: 20px; */
	margin-bottom: 20px;
	margin-top: 10px;
	/* background-color: #f9f9f9; */
	/* font-style: italic; */
	border-radius: 16px;
}


.clear {
	font-weight: 600;
	padding-left: 15px;
}

.clear:hover {
	padding-left: 15px;
}

.clear:active {
	transform: translateY(.3px);
}
</style>