<template>
	<div class="left-0 pt-[78px] z-50 fixed flex flex-col items-start w-[80px] ml-[1.5vw] ">
		<button v-if="storybookStyle === 'Viewer'" class="flex clear mt-[7px]" @click="playFromStart()">
			Play All
		</button>

		<button v-else-if="storybookStyle === 'Studio'" class="flex clear mt-[7px]" @click="replayRegion()">
			Replay
		</button>

<!-- 

		<button v-if="storybookStyle === 'Viewer' && playing" class="flex  clear mt-[7px]" @click="stop()">
			Stop
		</button> -->



		<!-- <button v-else-if="storybookStyle === 'Studio' && playing" class="flex clear  mt-[7px]" @click="stopRegion()">
			Stop
		</button> -->


		<button id="play" v-if="playing" class="flex clear  mt-[7px]" @click="play()">
			Pause
		</button>

		<button v-else-if="continueBoolean == true" class="flex clear mt-[7px]" @click="playNext()">
			Play
		</button>



		<button v-else class="flex clear mt-[7px]" @click="play()">
			Play
		</button>



		<!-- <button v-if="storybookStyle === 'Viewer'" class="flex clear  mt-[7px]" @click="replayRegion()">
			Repeat
		</button> -->

		<br>
		<!-- <div v-if="$store.state.options" class="flex flex-col "> -->
		<button v-if="playbackspeed <= 1.6" class="flex clear mt-[7px]" @click="speedUp()">Faster
		</button>

		<button v-else class="flex cursor-default important  mt-[7px]">Faster
		</button>

		<button v-if="playbackspeed >= .7" class="flex clear active:translate-y-[.3px] mt-[7px]" @click="slowDown()">
			Slower
		</button>

		<button v-else class="flex cursor-default important mt-[7px]">
			Slower
		</button>

		<div class="flex  pl-[1vw] ">
			<!-- <span
				:class="{ tibetantiny: $store.state.promptsObject.name == 'བོད་ཡིག', nottibetantiny: $store.state.promptsObject.name != 'བོད་ཡིག' }">{{
					$store.state.promptsObject.sPlaybackSpeed }}</span> -->

			<span class="text-xs ">{{
				playbackspeed
			}}x</span>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
import { getIdToken } from "firebase/auth";

export default {
	// name of component
	name: "PlayerCompare",

	// data that it inherits from parent component
	props: {
		audio_ID: {
			default: "",
		},
		playerPlayPause: {
			default: 0,
		},
		storybookStyle: {
			default: "Viewer",
		},
	},

	// declare the variables and default values that this component will need
	data: () => {
		return {
			playbackspeed: "1.0",
			isLoaded: false,
			loadingpercent: 0,
			readyVerification: 0,
			// peaksData: [],
			zoomnumber: 0,
			sendtobackendBoolean: false,
			startTime: "00:00:00", // the beginning of the highlighted region as calculated by wavesurfer OR manually input by the user, in HH:MM:SS
			currentTime: "00:00:00", // wherever the audio is currently playing as calculated by wavesurfer OR manually input by the user, in HH:MM:SS
			endTime: "00:00:00", // the end of the highlighted region as calculated by wavesurfer OR manually input by the user, in HH:MM:SS
			startTimeSeconds: 0, // the beginning of the highlighted region as calculated by wavesurfer, in seconds
			currentTimeSeconds: 0, // wherever the audio is currently playing as calculated by wavesurfer, in seconds
			endTimeSeconds: 0, // the end of the highlighted region as calculated by wavesurfer, in seconds
			totalDuration: 0, // the total length of the audio, in seconds
			playing: false,
			audioURL: "",
			updatingFromPrompter: false,
			repeat: false,
			hasRegion: false,
			autoscroll: true,
			continueBoolean: false,
			peaks: null,
			loadAudioBegun: false, // make sure loadAudio function only runs once
			timeoutOnce: false, // timeout the first instance of playing audio to help iOS mobile browsers
			tryingToPlayRegion: false, // this only turns to true if logic needs to depend on the isLoaded value.
		};
	},

	// watch these variables to see if they change.  if they do, then call the corresponding functions.
	watch: {


		"$store.state.playnoPausecounter": function () {
			// console.log("1")
			this.playWithoutPause();
		},
		"$store.state.safeToLoadAudio": function () {
			if (this.$store.state.safeToLoadAudio == 1 && this.loadAudioBegun == false) { this.loadAudio(); }
		},

		playerPlayPause: function () {
			// console.log("2")
			this.play();
		},

		playing: function () {
			if (this.playing) {
				this.$emit("playingBegun");
				this.continueBoolean = false
			}
		},

		isLoaded: function () {
			if (this.isLoaded == true && this.tryingToPlayRegion == true) {
				this.clearallregions();
				// console.log(this.$store.state.incomingEndTime)
				this.wavesurfer.addRegion({
					start: this.$store.state.incomingCurrentTime,
					end: this.$store.state.incomingEndTime,
					id: "region",
					loop: false,
				});

				this.startTimeSeconds = this.$store.state.incomingCurrentTime;
				this.endTimeSeconds = this.$store.state.incomingEndTime - .00005;
				this.startTime = this.secondsToTime(
					Math.round(this.$store.state.incomingCurrentTime)
				);
				this.endTime = this.secondsToTime(this.$store.state.incomingEndTime);
				this.seekTimestampfunction(this.$store.state.incomingCurrentTime);

				this.wavesurfer.play(this.$store.state.incomingCurrentTime);
				this.playing = true;


				this.timeoutOnce = true
			}
		},

		readyVerification: function () {
			if (this.readyVerification == 2) {
				// FLAG
				this.isLoaded = true;
				this.totalDuration = this.wavesurfer.getDuration();
				// console.log(this.totalDuration)
				this.$store.commit("updateAudioDuration", this.totalDuration * 1000);
				if (this.endTimeSeconds == 0) { // don't overwrite existing region.
					this.endTimeSeconds = this.totalDuration;
					this.endTime = this.secondsToTime(this.endTimeSeconds);

				}
				// this.wavesurfer.addRegion({
				// 	start: 0,
				// 	end: this.totalDuration,
				// 	id: "initialregion",
				// 	loop: false,
				// });
				this.wavesurfer.drawer.params.autoCenter = true;
				this.wavesurfer.enableDragSelection({
					id: "initialregion",
					loop: false,
				});

				let that = this;
				// length of output array/2, accuracy (irrelevant), don't popup a new window, start at 0,
				this.wavesurfer
					.exportPCM((this.totalDuration / 2) * 100, 10000, true, 0)
					.then(function (result) {
						if (that.sendtobackendBoolean == true) {
							// console.log("peaks to send:")
							// console.log(result)
							that.peaksToBackend(result);
						}
						else {
							console.log("duplicating peaks information")
							that.$store.commit("updatePeaksData", result)
						}
					});
			}
		},

		playbackspeed: function () {
			this.wavesurfer.setPlaybackRate(this.playbackspeed);
		},

		"$store.state.currentTimeUpdated": function () {

			if (this.$store.state.safeToLoadAudio == 1 && this.timeoutOnce == true) {
				// console.log("1.1")
				// console.log("playing from timestamp normal")
				this.clearallregions();
				this.wavesurfer.addRegion({
					start: this.$store.state.incomingCurrentTime,
					end: this.$store.state.incomingEndTime,
					id: "region",
					loop: false,
				});

				this.startTimeSeconds = this.$store.state.incomingCurrentTime;
				this.endTimeSeconds = this.$store.state.incomingEndTime - .00005;
				this.startTime = this.secondsToTime(
					Math.round(this.$store.state.incomingCurrentTime)
				);
				this.endTime = this.secondsToTime(this.$store.state.incomingEndTime);
				this.seekTimestampfunction(this.$store.state.incomingCurrentTime);

				this.wavesurfer.play(this.$store.state.incomingCurrentTime);
				this.playing = true;

			}
			else if (this.isLoaded == true) {
				setTimeout(() => {

					// console.log("1.2")
					// console.log("playing from timestamp delayed")
					this.clearallregions();
					// console.log(this.$store.state.incomingEndTime)
					this.wavesurfer.addRegion({
						start: this.$store.state.incomingCurrentTime,
						end: this.$store.state.incomingEndTime,
						id: "region",
						loop: false,
					});

					this.startTimeSeconds = this.$store.state.incomingCurrentTime;
					this.endTimeSeconds = this.$store.state.incomingEndTime - .00005;
					this.startTime = this.secondsToTime(
						Math.round(this.$store.state.incomingCurrentTime)
					);
					this.endTime = this.secondsToTime(this.$store.state.incomingEndTime);
					this.seekTimestampfunction(this.$store.state.incomingCurrentTime);

					this.wavesurfer.play(this.$store.state.incomingCurrentTime);
					this.playing = true;


					this.timeoutOnce = true
				}, 300);
			} else if (this.isLoaded == false) { this.tryingToPlayRegion = true }


		},
		"$store.state.playerRerender": function () {
			// console.log("4")
			this.shouldRerender(this.$store.state.playerRerender);
		},
		"$store.state.regionRerender": function () {
			// console.log("5")
			this.startTime = this.secondsToTime(
				Math.round(this.$store.state.startTimePrompter)
			);
			this.endTime = this.secondsToTime(
				Math.round(this.$store.state.endTimePrompter)
			);
			this.updateRegionFromPrompter();
		},
	},

	// these are variables whose values are dynamically updated when necessary.
	computed: {

		// the beginning of the highlighted region as manually typed in by the user, in seconds
		startTimeNumber() {
			let startTimeArray = this.startTime.split(":");
			return (
				startTimeArray[0] * 3600 +
				startTimeArray[1] * 60 +
				startTimeArray[2] * 1
			);
		},

		// the end of the highlighted region as manually typed in by the user, in seconds
		endTimeNumber() {
			let endTimeArray = this.endTime.split(":");
			return (
				endTimeArray[0] * 3600 + endTimeArray[1] * 60 + endTimeArray[2] * 1
			);
		},

		// wherever the audio is currently playing as manually typed in by the user, in seconds
		currentTimeNumber() {
			let currentTimeArray = this.currentTime.split(":");
			return (
				currentTimeArray[0] * 3600 +
				currentTimeArray[1] * 60 +
				currentTimeArray[2] * 1
			);
		},

		// the wavesurfer "number" that the user wants to seek to, based on their manual editing of the current time HH:MM:SS
		currentSeekNumber() {
			return this.currentTimeNumber / this.totalDuration;
		},
	},

	unmounted() {
		document.removeEventListener("visibilitychange", function () {
			if (document.hidden) {
				that.pausePlayer()

			}
		});
		if (this.wavesurfer) {
			this.wavesurfer.destroy();
		}
	},

	mounted() {
		this.sendtobackendBoolean = false;

		if (this.$store.state.safeToLoadAudio == 1 && this.loadAudioBegun == false) { this.loadAudio(); }

	},

	methods: {

		restartStudio() {

			this.$emit("restartStudio");

		},

		// pause and return to beginning of audio
		stop() {


			this.wavesurfer.pause();
			this.wavesurfer.seekTo(0);
			this.playing = false;
		},

		// pause and return to beginning of region
		stopRegion() {
			this.wavesurfer.pause();
			this.wavesurfer.seekTo(this.startTimeSeconds / this.totalDuration);
			this.playing = false;
		},

		replayRegion() {

			if (this.$store.state.safeToLoadAudio == 1 && this.timeoutOnce == true) {
				if (this.startTimeSeconds != 0) {
					this.wavesurfer.play(this.startTimeSeconds);
				}
				else {
					this.seekTimestampfunction(this.$store.state.recentHighlight);
					if (!this.playing) {
						this.play();
					} else if (this.playing) {
						this.play();
						this.play();
					}
				}
				this.playing = true



			} else {
				this.playFromStart()


			}

		},

		speedUp() {
			this.playbackspeed = (parseFloat(this.playbackspeed) + .2).toFixed(1)
		},

		slowDown() {
			this.playbackspeed = (parseFloat(this.playbackspeed) - .2).toFixed(1)
		},

		async loadAudio() {
			this.loadAudioBegun = true

			const hiddenContainer2 = document.createElement('div');
			hiddenContainer2.style.display = 'none'; // Hide the container
			document.body.appendChild(hiddenContainer2); // Attach to DOM (but invisible)

			const hiddenContainer3 = document.createElement('div');
			hiddenContainer3.style.display = 'none'; // Hide the container
			document.body.appendChild(hiddenContainer3); // Attach to DOM (but invisible)




			// https://wavesurfer-js.org
			this.wavesurfer = WaveSurfer.create({
				container: hiddenContainer2,
				backend: "MediaElement",
				plugins: [
					WaveSurfer.regions.create({
						maxRegions: 1,
					}),
					WaveSurfer.minimap.create({
						container: hiddenContainer3,
						showOverview: true,
					}),
				],
			});



			if (this.$store.state.user) {
				// REFRESH ID TOKEN FIRST AND WAIT FOR IT
				await getIdToken(this.$store.state.user)
					.then((idToken) => {
						this.$store.commit("SetIdToken", idToken);
						// console.log(this.$store.state.idToken)
					})
					.catch((error) => {
						// An error happened.
						console.log("Oops. " + error.code + ": " + error.message);
					});
			}

			//get secure url from server
			this.$store.commit("updateAudioDuration", 0);
			this.$store.commit("removePeaksData");
			const apiUrl = process.env.VUE_APP_api_URL + "s3/presignedgeturl";
			fetch(apiUrl, {
				method: "POST",

				headers: {
					"Content-Type": "application/json",
					Authorization: this.$store.state.idToken,
				},

				body: JSON.stringify({
					audio_ID: this.audio_ID,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					// console.log(data)
					this.audioURL = data["url"];
					this.peaks = data["peaks"]
					if (data["peaks"]) {
						// console.log("loading peaks from backend!")
						// console.log(JSON.parse(data["peaks"]))
						// console.log(Math.max(...JSON.parse(data["peaks"])))
						// console.log(JSON.parse(data["peaks"]).reduce((max, v) => max >= v ? max : v, -Infinity))

						this.wavesurfer.load(this.audioURL, JSON.parse(data["peaks"]));
					} else if (!data["peaks"]) {
						// console.log("generating new peaks on frontend")
						this.wavesurfer.load(this.audioURL);
						this.sendtobackendBoolean = true;
					}
				})
				.catch((error) => {
					console.error("Error:", error);
				});

			let that = this;

			document.addEventListener("visibilitychange", function () {
				if (document.hidden) {
					that.pausePlayer()

				}
			});

			this.wavesurfer.on('error', function (err) {
				console.warn("error", err?.message || err);
			});

			// When the audio file is loaded, update our data about the length of the audio file, and create a new highlighted and draggable/adjustable region that spans the entire waveform
			this.wavesurfer.on("waveform-ready", function () {
				// console.log("waveform ready")
				that.readyVerification += 1;
			});

			// When the audio file is loaded, update our data about the length of the audio file, and create a new highlighted and draggable/adjustable region that spans the entire waveform
			this.wavesurfer.on("ready", function () {
				// console.log("audio ready")
				that.readyVerification += 1;
			});

			// whenever the highlighted region or either of its bounds is dragged, update our data about where the region begins and ends accordingly
			this.wavesurfer.on("region-update-end", (region, event) => {
				// console.log(region)
				that.startTimeSeconds = region.start;
				that.endTimeSeconds = region.end - .00005;
				// console.log(that.startTimeSeconds)
				// console.log(that.endTimeSeconds)
				// that.startTime = that.secondsToTime(that.startTimeSeconds);
				// that.endTime = that.secondsToTime(that.endTimeSeconds);
				that.$store.commit("updateStartTimePrompter", that.startTimeSeconds);
				that.$store.commit("updateEndTimePrompter", that.endTimeSeconds);
				that.$store.commit("forceTriggerNewText");

				that.startTime = that.secondsToTime(
					Math.round(that.$store.state.startTimePrompter)
				);
				that.endTime = that.secondsToTime(
					Math.round(that.$store.state.endTimePrompter)
				);
			});

			this.wavesurfer.on("region-created", (region, event) => {
				// console.log(region);
				if (that.updatingFromPrompter == false) {
					// console.log("creating region, not from prompter");
					that.startTimeSeconds = region.start;
					that.endTimeSeconds = region.end - .00005;
					// console.log(that.startTimeSeconds)
					// console.log(that.endTimeSeconds)
					// that.startTime = that.secondsToTime(that.startTimeSeconds);
					// that.endTime = that.secondsToTime(that.endTimeSeconds);
					that.$store.commit("updateStartTimePrompter", that.startTimeSeconds);
					that.$store.commit("updateEndTimePrompter", that.endTimeSeconds);
					if (region.id != "initialregion") {
						that.$store.commit("forceTriggerNewText");
					}
				} else if (that.updatingFromPrompter == true) {
					// console.log("creating region from prompter");
					that.startTimeSeconds = that.$store.state.startTimePrompter;
					that.endTimeSeconds = that.$store.state.endTimePrompter;
					that.updatingFromPrompter = false;
				}
				that.hasRegion = true;
				// that.startTime=that.secondsToTime(Math.round(that.$store.state.startTimePrompter))
				// that.endTime=that.secondsToTime(Math.round(that.$store.state.endTimePrompter))
			});

			// calculate how much of the audio file has been loaded, so far
			this.wavesurfer.on("loading", function (progress) {
				that.loadingpercent = progress;
			});

			// whenever the audio is playing, update our data about where we are in the file accordingly, including in the Vuex store
			this.wavesurfer.on("audioprocess", function () {
				that.currentTimeSeconds = that.wavesurfer.getCurrentTime();
				that.currentTime = that.secondsToTime(that.currentTimeSeconds);
				that.$store.commit(
					"updateAudioTime",
					Math.round(that.currentTimeSeconds * 100)
				);

				if (
					that.currentTimeSeconds >= that.endTimeSeconds &&
					that.isLoaded == true
				) {
					// console.log("step 1")
					// console.log(that.currentTimeSeconds + " is greater than or equal to " + that.endTimeSeconds + " out of " + that.totalDuration)
					if (that.storybookStyle === "Studio") {
						that.pausePlayer();
						that.wavesurfer.seekTo(that.startTimeSeconds / that.totalDuration);
						that.$store.commit("updateHighlights");
					}
					else if (that.storybookStyle === "Viewer") {
						that.pausePlayer();
						that.wavesurfer.seekTo(that.endTimeSeconds / that.totalDuration);
						that.$store.commit("updateHighlights");
						// that.clearallregions()
						that.continueBoolean = true
					}
				}
				// else {
				// console.log(that.currentTimeSeconds + " is NOT greater than or equal to " + that.endTimeSeconds + " out of " + that.totalDuration)
				// }
			});

			this.wavesurfer.on("finish", function () {
				console.log("finished")
				if (that.repeat == true) {
					that.wavesurfer.seekTo(that.startTimeSeconds / that.totalDuration);

					that.$store.commit("updateHighlights");
					that.pausePlayer();
					that.play();
				} else {
					that.pausePlayer();
				}
			});

			// whenever the audio jumps from one position to another for whatever reason, if the audio is playing but the cursor is now out of bounds of the highlighted region, then pause the player
			this.wavesurfer.on("seek", function (position) {
				that.currentTimeSeconds = position * that.totalDuration;
				// console.log(that.startTimeSeconds)
				// console.log(that.currentTimeSeconds)
				// console.log(that.endTimeSeconds)
				if (
					// that.playing &&
					Math.round(that.currentTimeSeconds * 100) / 100 < Math.round(that.startTimeSeconds * 100) / 100 ||
					Math.round(that.currentTimeSeconds * 100) / 100 > Math.round(that.endTimeSeconds * 100) / 100
				) {
					that.clearallregions();
					that.pausePlayer();
				}
				// regardless whether the cursor is dropped inside or outside of the highlighted region, update the data about where we are in the audio file, both within this component and in the Vuex store.
				that.currentTime = that.secondsToTime(that.currentTimeSeconds);
				that.$store.commit(
					"updateAudioTime",
					Math.round(that.currentTimeSeconds * 100)
				);
			});





			// if (this.$store.state.safeToLoadAudio == 1 && this.peaks) {
			// 	// console.log("loading peaks from backend!")
			// 	// console.log(JSON.parse(data["peaks"]))
			// 	// console.log(Math.max(...JSON.parse(data["peaks"])))
			// 	// console.log(JSON.parse(data["peaks"]).reduce((max, v) => max >= v ? max : v, -Infinity))

			// 	this.wavesurfer.load(this.audioURL, JSON.parse(this.peaks));
			// } else if (this.$store.state.safeToLoadAudio == 1 && !this.peaks) {
			// 	// console.log("generating new peaks on frontend")
			// 	this.wavesurfer.load(this.audioURL);
			// 	this.sendtobackendBoolean = true;
			// }
		},

		peaksToBackend(generatedpeaks) {
			console.log("starting to stringify")
			let peaksString = "["

			for (let i = 0; i < generatedpeaks.length - 1; i++) {
				let string1 = JSON.stringify(generatedpeaks[i])
				peaksString = peaksString + string1 + ", "
			}

			peaksString = peaksString + JSON.stringify(generatedpeaks[generatedpeaks.length - 1]) + "]"

			console.log("peaks stringified and about to be sent to backend")

			fetch(
				process.env.VUE_APP_api_URL + "audio/" + this.audio_ID + "/public/",
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",

						Authorization: this.$store.state.idToken,
					},
					body: JSON.stringify({
						// url: "coverimage.jpg",
						// title: this.title,
						// description: this.description,
						// public: this.publictf,
						// archived: false,
						// shared_with: [],
						peaks: peaksString,
					}),
				}
			)
				.then((response) => {
					console.log("response received")
					return response.json();
				})
				.then((response) => {
					console.log(response);
					console.log("about to duplicate peaks information")
					this.$store.commit("updatePeaksData", generatedpeaks)
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		},

		// if we get news that this audio file just completed being uploaded, then rerender this audio player
		shouldRerender(incomingID) {
			if (incomingID == this.audio_ID) {
				this.$emit("rerenderPlayer");
			}
		},

		zoom() {
			this.wavesurfer.zoom(Number(this.zoomnumber));
		},

		toggleRepeat() {
			if (this.repeat == true) {
				this.repeat = false;
			} else if (this.repeat == false) {
				this.repeat = true;
			}
			this.playWithoutPause();
		},
		toggleAutoscroll() {
			if (this.autoscroll == true) {
				this.wavesurfer.drawer.params.autoCenter = false;
				this.autoscroll = false;
			} else if (this.autoscroll == false) {
				this.wavesurfer.drawer.params.autoCenter = true;
				this.autoscroll = true;
			}
		},


		playFromStart() {
			// console.log("2")
			if (this.$store.state.safeToLoadAudio == 1 && this.timeoutOnce == true) {
				// console.log("2.1")
				this.clearallregions()
				this.wavesurfer.play(0)
				this.playing = true
			} else {
				setTimeout(() => {
					// console.log("2.2")
					this.clearallregions()
					this.wavesurfer.play(0)
					this.playing = true
					this.timeoutOnce = true
				}, 300);
			}
		},

		playNext() {
			if (this.continueBoolean == true) {
				this.clearallregions()
				this.wavesurfer.play(this.currentTimeSeconds);
				this.playing = !this.playing;
			}
		},

		play() {
			if (this.$store.state.safeToLoadAudio == 1 && this.timeoutOnce == true) {

				// console.log("3.1")

				if (!this.playing) {
					// when the player starts playing, make sure it plays from whenever is currently displayed in the "current time" box that the user is also able to manually inpput into, unless of course that value is outside of the highlighted region
					if (
						Math.round(this.currentTimeSeconds * 100) / 100 < Math.round(this.endTimeSeconds * 100) / 100 &&
						Math.round(this.currentTimeSeconds * 100) / 100 >= Math.round(this.startTimeSeconds * 100) / 100
					) {
						this.wavesurfer.play(this.currentTimeSeconds);
						this.playing = !this.playing;
					} else {
						this.clearallregions()
						this.wavesurfer.play(this.startTimeSeconds);
						this.playing = !this.playing;
					}
				} else if (this.playing) {
					// if you click "pause," then pause the player
					this.pausePlayer();
				}


			} else {

				setTimeout(() => {

					// console.log("3.2")


					if (!this.playing) {
						// when the player starts playing, make sure it plays from whenever is currently displayed in the "current time" box that the user is also able to manually inpput into, unless of course that value is outside of the highlighted region
						if (
							Math.round(this.currentTimeSeconds * 100) / 100 < Math.round(this.endTimeSeconds * 100) / 100 &&
							Math.round(this.currentTimeSeconds * 100) / 100 >= Math.round(this.startTimeSeconds * 100) / 100
						) {
							// console.log("playing inside region");
							// console.log(this.startTimeSeconds)
							// console.log(Math.round(this.currentTimeSeconds*100)/100)
							// console.log(this.endTimeSeconds)
							this.wavesurfer.play(this.currentTimeSeconds);
							this.playing = !this.playing;
						} else {
							// console.log("playing from start of region");
							// console.log(this.startTimeSeconds)
							// console.log(Math.round(this.currentTimeSeconds*100)/100)
							// console.log(this.endTimeSeconds)
							this.clearallregions()
							this.wavesurfer.play(this.startTimeSeconds);
							this.playing = !this.playing;
						}
					} else if (this.playing) {
						// if you click "pause," then pause the player
						this.pausePlayer();
					}

					this.timeoutOnce = true

				}, 300);
			}
		},

		playWithoutPause() {
			if (!this.playing) {
				// when the player starts playing, make sure it plays from whenever is currently displayed in the "current time" box that the user is also able to manually inpput into, unless of course that value is outside of the highlighted region
				if (
					this.currentTimeSeconds <= this.endTimeSeconds &&
					this.currentTimeSeconds >= this.startTimeSeconds
				) {
					// console.log("playing inside region");
					this.wavesurfer.play(this.currentTimeSeconds);
					this.playing = !this.playing;
				} else {
					// console.log("playing from start of region");
					this.wavesurfer.play(this.startTimeSeconds);
					this.playing = !this.playing;
				}
			}
		},

		// convert a value from seconds to HH:MM:SS
		secondsToTime(seconds) {
			var date = new Date(1970, 0, 1);
			date.setSeconds(seconds);
			return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
		},

		pausePlayer() {
			this.wavesurfer.pause();
			this.playing = false;
		},

		// when the user clicks in the "Viewer" component, it starts playing the audio player from this timestamp and updates the HH:MM:SS display of current time accordingly
		seekTimestampfunction(timestamp) {
			// console.log(timestamp)
			if (this.totalDuration > 0) {
				this.wavesurfer.seekTo(timestamp / this.totalDuration);

				this.$store.commit("updateHighlights");
				this.currentTime = this.secondsToTime(timestamp);
			}
		},

		// when the user submits a new manual input of HH:MM:SS current time, the cursor moves accordingly and the change is also communicated to the Vuex store
		seekfunction() {
			this.wavesurfer.seekTo(this.currentSeekNumber);

			this.$store.commit("updateHighlights");
		},

		// clear the highlighted region and reset the HH:MM:SS displays of start and end time accordingly
		clearallregions() {
			// console.log("in clear regions")
			this.wavesurfer.clearRegions();
			// console.log(this.updatingFromPrompter);
			this.startTime = "00:00:00";
			this.endTime = this.secondsToTime(this.totalDuration);
			// console.log("end time: " + this.endTime)
			this.startTimeSeconds = 0; // wavesurfer's "region-update-end" event doesn't catch this so I am doing it manually here
			this.endTimeSeconds = this.totalDuration; // wavesurfer's "region-update-end" event doesn't catch this so I am doing it manually here			this.$store.commit(
			this.$store.commit("updateStartTimePrompter", 0);
			this.$store.commit("updateEndTimePrompter", this.totalDuration);
			this.hasRegion = false;
		},

		// change the highlighted region based on manual HH:MM:SS inputs of start and end times by the user
		updateRegion() {
			this.wavesurfer.clearRegions();
			// console.log("end time: " + this.endTime)
			this.wavesurfer.addRegion({
				start: this.startTimeNumber,
				end: this.endTimeNumber,
				id: "region",
				loop: false,
			});
		},

		// change the highlighted region based on manual HH:MM:SS inputs of start and end times by the user
		updateRegionFromPrompter() {
			this.wavesurfer.clearRegions();
			this.updatingFromPrompter = true;
			this.wavesurfer.addRegion({
				start: this.$store.state.startTimePrompter,
				end: this.$store.state.endTimePrompter,
				id: "region",
				loop: false,
			});
			this.startTime = this.secondsToTime(
				Math.round(this.$store.state.startTimePrompter)
			);
			this.endTime = this.secondsToTime(
				Math.round(this.$store.state.endTimePrompter)
			);
			this.wavesurfer.seekTo(this.startTimeSeconds / this.totalDuration);
			this.$store.commit("updateHighlights");
			// this.startTimeSeconds = this.$store.state.startTimePrompter; // wavesurfer's "region-update-end" event doesn't catch this so I am doing it manually here
			// this.endTimeSeconds = this.$store.state.endTimePrompter; // wavesurfer's "region-update-end" event doesn't catch this so I am doing it manually here
			if (!this.playing) {
				// this.play();
			} else if (this.playing) {
				this.play();
				this.play();
			}
		},
	},
};
</script>

<style scoped>
.clear {
	touch-action: manipulation;
	color: #15803d;
	font-weight: 700;
	width: 100%;
	padding-right: 20px;
	/* border-left: 5px solid #15803d; Add a green vertical bar */
	/* padding-left: 15px; Add some space between the button text and the bar */

	/* align-items: flex-start; */
}

.clear:hover {
	color: #0369a1;
	/*#0369a1 064e3b 1d4ed8*/
	text-decoration-line: underline;
	font-weight: 700;
	/* align-items: flex-start; */
}

.clear:active {
	transform: translateY(.3px);
	font-weight: 700;
	/* Gives a pressed-down effect */
}


.important {
	touch-action: manipulation;
	width: 100%;
	color: #1d4ed8;
	/* align-items: flex-start; */
	position: relative;
	/* This is important for the position of the pseudo-element */
	padding-right: 20px;
	font-weight: 700;
}

.important::after {
	content: "";
	font-weight: 700;
	position: absolute;
	top: 0;
	right: 0;
	/* Align to the right of the button */
	width: 4px;
	/* Width of the vertical line */
	height: 20px;
	background-color: #1d4ed8;
	/* Blue line color */
	/* margin-left: 30px; */
}
</style>
